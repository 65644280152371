<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="eec"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>

    <v-col cols="12" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-input`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.input"
        step-label="eec"
        :label="$lang.labels.input"
        required
        :readonly="!canEdit"
        @change="handleChange('input', $event)"
        @openEditor="$emit('openEditor', $event, 'input',
                           { text: '', value: properties.input, parentType: 'input' }
        )"
      />
    </v-col>

    <v-col cols="12" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-command`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.command"
        :label="$lang.labels.command"
        step-label="eec"
        required
        :readonly="!canEdit"
        @change="handleChange('command', $event)"
        @openEditor="$emit('openEditor', $event, 'command',
                           { text: '', value: properties.command, parentType: 'command' }
        )"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-resultCode`"
        v-model="properties.resultCode"
        outlined
        dense
        data-cy="eec-resultCode"
        :label="$lang.labels.resultCode"
        :readonly="!canEdit"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-resultErrors`"
        v-model="properties.resultErrors"
        outlined
        dense
        data-cy="eec-resultErrors"
        :label="$lang.labels.resultErrors"
        :readonly="!canEdit"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="eec-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="eec"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>

    <v-col cols="12" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-input`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.input"
        step-label="eec"
        :label="$lang.labels.input"
        required
        :readonly="!canEdit"
        @change="handleChange('input', $event)"
        @openEditor="$emit('openEditor', $event, 'input', { text: '', value: properties.input, parentType: 'input' })"
      />
    </v-col>

    <v-col cols="12" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-command`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.command"
        :label="$lang.labels.command"
        step-label="eec"
        required
        :readonly="!canEdit"
        @change="handleChange('command', $event)"
        @openEditor="$emit('openEditor', $event, 'command', { text: '', value: properties.command, parentType: 'command' })"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-resultCode`"
        v-model="properties.resultCode"
        outlined
        dense
        data-cy="eec-resultCode"
        :label="$lang.labels.resultCode"
        :readonly="!canEdit"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-resultErrors`"
        v-model="properties.resultErrors"
        outlined
        dense
        data-cy="eec-resultErrors"
        :label="$lang.labels.resultErrors"
        :readonly="!canEdit"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="eec-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {
  ScriptEnginesSelect,
  TextInputWithEditorButton
} from './components'
import { castValueForStep } from '@/utils/helpers'
import StepMixin from './StepMixin'

export default {
  name: 'ExecuteExternalCmdStep',
  components: {
    ScriptEnginesSelect,
    TextInputWithEditorButton
  },
  mixins: [StepMixin],
  created() {
    if (!this.properties?.input || typeof this.properties.input !== 'string') {
      this.properties.input = ''
    }
  },
  methods: {
    handleChange(field, value) {
      this.$set(this.properties, field, castValueForStep(
        field,
        value,
        {
          shouldBeString: ['resultCode', 'resultErrors'] // Specify fields to cast as strings
        }
      ))
    }
  }
}
</script>
